/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style

export const isUserLoggedIn = () => {
  const d = new Date()
  if (localStorage.getItem('accesstoken') && localStorage.getItem('userData')) {
    if (JSON.parse(localStorage.getItem('accesstoken')).exp < parseInt((d.getTime() / 1000), 10)) {
      if (localStorage.getItem('userData') && localStorage.getItem('accesstoken')) {
        localStorage.removeItem('userData')
        localStorage.removeItem('accesstoken')
        window.location.href = '/login'
      }
    }
  }
  return Boolean(localStorage.getItem('userData'))
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
